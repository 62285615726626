import { Injectable } from '@angular/core';
import { HttpRequest, HttpEvent } from '@angular/common/http';

// RxJs
import { Observable } from 'rxjs';

// Root Singleton Services
import { ApiService, HttpClientService } from 'app/core/services';

@Injectable()
export class UploadService {
  apiUrl: string;

  constructor(
    private http: HttpClientService, 
    private apiService: ApiService) {
      
    this.apiUrl = `${this.apiService.apiUrl}files`;
  }

  uploadImage(formData: any): Observable<HttpEvent<{}>> {
    const httpRequest = new HttpRequest('POST', `${this.apiUrl}/images`,
      formData, { headers: this.http.createUploadHeader(), reportProgress: true });

    return this.http.request(httpRequest);
  }

  uploadFile(formData: any): Observable<HttpEvent<{}>> {
    const httpRequest = new HttpRequest('POST', `${this.apiUrl}`,
      formData, { headers: this.http.createUploadHeader(), reportProgress: true });

    return this.http.request(httpRequest);
  }
}