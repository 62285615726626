import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ErrorMessage } from 'app/domain/errorMessage';

import { Router } from '@angular/router';

// Root singleton Services
// import { StorageHelperService } from 'app/core/services/storage-helper.service';
// NgRx
import { Store } from '@ngrx/store';
// import * as authActions from 'app/auth/store/auth.actions';
import * as fromRoot from 'app/core/store';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>) {
  }

  public format(err: any) : ErrorMessage {
    var errorMessage = new ErrorMessage();
    var defaultErrorMessage = "An internal error occured.";
    if (err) {
      if(err.error){
        if(typeof err.error === "object")
        {
          errorMessage.error = err.error
          errorMessage.message = err.error.message
          errorMessage.statusCode = err.status
          return errorMessage
        }else
        {
          errorMessage.message = err.error;
          errorMessage.statusCode = err.status;
          return errorMessage;
        }
      }
      if(err.status){
        errorMessage.statusCode = err.status;
      }
      if (err.statusText) {
        errorMessage.title = err.statusText;
      }
      if (err.error && err.error.message) {
        errorMessage.message = err.error.message;
      }
      else if (err.error && err.error.error_description) {
        errorMessage.message = err.error.error_description;
      }
      else if (err.message) {
        errorMessage.message = err.message;
      }
      else if (typeof(err.error) === 'string') {
        errorMessage.message = err.error;
      }
      else if (err.message === 'string') {
        errorMessage.message = err.message;
      }
      else {
        errorMessage.message = defaultErrorMessage;
      }
    }
    else {
      errorMessage.message = "An internal error occured.";
    }
    return errorMessage;
  }

  public handle(err: any) {
    var errorMessage = this.format(err);

    return throwError(errorMessage);
  }
}
