import { AbstractControl, ValidationErrors } from '@angular/forms';

export const PasswordMatchValidator = (firstField: string, secondField: string) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.get(firstField);
    const confirmPassword = control.get(secondField);

    return password && confirmPassword && password.value === confirmPassword.value
      ? null
      : { passwordMismatch: true };
  };
};
