

export class LoginRequest {
    username: string;
    password: string;

    constructor() { 
   
   }
}

    
