import {Injectable} from '@angular/core';

// Root singleton Services
import {SessionStorageService} from './sessionStorage.service';

// App
import {LoginResponseObj} from 'app/domain/loginResponseObj';
import {UserAppSettings} from 'app/domain/userAppSettings';

@Injectable({
  providedIn: 'root'
})
export class StorageHelperService {
  userObjectKey = 'userObject';
  userAppSettingsKey = 'appSettings';
  panelObjectKey = 'panelObject';
  filtersObjectKey = 'filtersObject';
  virtualAgentsKey = 'virtualAgents';

  constructor(private sessionStorage: SessionStorageService) {}

  _userAppSettings: UserAppSettings;

  get userAppSettings(): UserAppSettings {
    let userAppSettings = new UserAppSettings();
    const sessionStorageItem = this.sessionStorage.get(this.userAppSettingsKey);
    userAppSettings = userAppSettings.parseFromSessionStorage(sessionStorageItem);
    return userAppSettings;
  }

  _userObject: LoginResponseObj;

  get userObject(): LoginResponseObj {
    let userObject = new LoginResponseObj();
    const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
    userObject = userObject.parseFromSessionStorage(sessionStorageItem);
    return userObject;
  }

  get filters(): number[] {
    if (this.sessionStorage.get(this.filtersObjectKey) === null) {
      this.setFilters([]);
    }

    // Replace object with index
    const obj = JSON.parse(this.sessionStorage.get(this.filtersObjectKey));
    if (obj.length > 0) {
      if (obj[0].hasOwnProperty('type')) {
        this.setFilters([0, 1, 2]);
      }
    }
    // Replace object with index
    return JSON.parse(this.sessionStorage.get(this.filtersObjectKey));
  }

  get virtualAgents(): number[] {
    if (this.sessionStorage.get(this.virtualAgentsKey) === null) {
      return null;
    }
    return JSON.parse(this.sessionStorage.get(this.virtualAgentsKey));
  }

  SetUserAutoTakeoverActive(isActive: boolean) {
    const autoTakeoverUserObject = this.userObject;
    autoTakeoverUserObject.isActive = isActive;
    this.sessionStorage.set(this.userObjectKey, JSON.stringify(autoTakeoverUserObject));
  }

  setFilters(filterIndices: number[]) {
    this.sessionStorage.set(this.filtersObjectKey, JSON.stringify(filterIndices));
  }

  setVirtualAgents(virtualAgents: string) {
    this.sessionStorage.set(this.virtualAgentsKey, JSON.stringify(virtualAgents.split(',').map(x => +x)));
  }

  clear() {
    this.sessionStorage.clear();
  }
}
