<div id="sidebar-right" [ngClass]="{ 'sidebar-right-open': navService.sidebarRightOpen == true }">
  <div class="sidebar-right-buttons">
    <button mat-icon-button (click)="openSidebarRight()" class="test-bot-button">
      <mat-icon>play_circle_filled</mat-icon>
    </button>
  </div>
  <div class="sidebar-right-content">
    <div id="sidebar-auto-padding">
      <ebo-webchat *ngIf="navService.sidebarRightOpened == true" [isTest]="true"></ebo-webchat>
    </div>
  </div>
</div>
