// import { storeFreeze } from 'ngrx-store-freeze';
import { AppState } from './index';
import { createReducer, on, Action, MetaReducer, ActionReducerMap } from '@ngrx/store';
import * as fromAuth from 'app/auth/store/auth.reducer';
// import * as fromRouter from '@ngrx/router-store';
import { InjectionToken } from '@angular/core';

export const metaReducers: MetaReducer<AppState>[] = [];

export const ROOT_REDUCERS =
 new InjectionToken<ActionReducerMap<AppState, Action>>('Root reducers', {
  factory: () => ({
    auth: fromAuth.authReducer
  }),
});
