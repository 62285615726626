import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators';
import { UsersService } from '../../views/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(private userService: UsersService, private router: Router, private snackbar: MatSnackBar) {}

  isAdminOrEboAdmin(userId: string): Observable<boolean> {
    return this.userService.getUserRoles(userId).pipe(
      first(),
      map((obj: any) => {
        if (obj?.roles?.some((name: string) => name === 'Admin' || name === 'EboAdmin')) {
          return true;
        } else {
          this.router.navigate(['/settings/view']);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigate(['/settings/view']);
        this.snackbar.open(`Error: Unable to check user roles`, 'Dismiss', { duration: 3000 });
        return of(false);
      })
    );
  }
}
