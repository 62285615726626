<mat-card class="chat-card" fxLayout='column' fxFlexFill>
  <mat-card-title fxFlex='0 0 auto'>
    <div class="title" >
      <span>EBO Inspector</span>
      <mat-icon matTooltip="Real time feed of all core functions being executed to power this conversation"
                matTooltipPosition="above">info</mat-icon>
    </div>
  </mat-card-title>
  <mat-card-content fxFlex>
    <div [perfectScrollbar] class="content-container">
    <div @inspectorMessages *ngFor="let message of inspectorMessages"  >
      <div class="content">
        <div class="maincontent">
          <div _ngcontent-c9="" class="content-item ng-star-inserted">
            <div class="content-item-icon">
              <mat-icon class="insp-icon" [ngClass]="message.expanded ? 'insp-icon-selected' : ''">
                {{message.icon}}
              </mat-icon>
            </div>
            <div (click)="openVariable(message)" class="insp-expanded"
              [ngClass]="message.expanded ? 'insp-selected' : ''">
              {{message.name}}
              <mat-icon [inline]="true" class="message-icon">
                {{ message.expanded ? 'arrow_drop_up' : 'arrow_drop_down'}}
              </mat-icon>
            </div>
            <div class="message-date">
              {{message.date}}
            </div>
            <div class="message-processing-time">
              <div>{{message.processingTime}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="message.expanded" class="message-expanded">
          <div *ngIf="message.nlpMessage">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">intent</span> =
                '{{message.nlpMessage.topScoringIntentName}}'
              </div>
              <div>
                <span class="highlight-text">score</span> =
                '{{message.nlpMessage.topScoringIntentScore}}'
              </div>
              <div *ngIf="message.nlpMessage.sentimentAnalysisLabel">
                <div class="sentiment">
                  <span class="highlight-text">sentiment</span> =
                  '{{message.nlpMessage.sentimentAnalysisLabel}}'
                </div>
                <div>
                  <span class="highlight-text">score</span> =
                  '{{message.nlpMessage.sentimentAnalysisScore}}'
                </div>
              </div>
              <div *ngFor="let entity of message.nlpMessage.entities" class="entities">
                <div>
                  <span class="highlight-text">
                    entity
                    name
                  </span> = '{{entity.name}}'
                </div>
                <div>
                  <span class="highlight-text">type</span> =
                  '{{entity.type}}'
                </div>
                <div *ngIf="entity.score">
                  <span class="highlight-text">score</span> =
                  '{{entity.score}}'
                </div>
                <div *ngIf="entity.value">
                  <span class="highlight-text">value</span> =
                  '{{entity.value}}'
                </div>
                <div *ngIf="entity.normalizedValue">
                  <span class="highlight-text">normalized value</span> =
                  '{{entity.normalizedValue}}'
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="message.dialogInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">name</span> =
                '{{message.dialogInfo.dialogName}}'
              </div>
            </div>
          </div>
          <div *ngIf="message.nlpMatchInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">dialog</span>
                = '{{message.nlpMatchInfo.dialogName}} ({{message.nlpMatchInfo.dialogId}})'
              </div>
              <div *ngIf="message.nlpMatchInfo.intentExpression">
                <span class="highlight-text">intent</span>
                = '{{message.nlpMatchInfo.intentExpression}}'
              </div>
              <div *ngIf="message.nlpMatchInfo.entityExpression">
                <span class="highlight-text">entity</span>
                = '{{message.nlpMatchInfo.entityExpression}}'
              </div>
            </div>
          </div>
          <div *ngIf="message.retryInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">dialog name</span> =
                '{{message.retryInfo.dialogName}}'
              </div>
            </div>
          </div>
          <div *ngIf="message.errorInfo">
            <div class="message-expanded-content">
              <div>
                <span class="exception">exception</span> =
                '{{message.errorInfo.errorMessage}}'
              </div>
              <div>
                <span class="exception">stack trace</span> =
                <div class="insp-stackTraceContainer"
                     [ngClass]="message.errorInfo.showStack ? 'insp-stackTraceShowMore' : ''">
                  '{{message.errorInfo.stackTrace}}'
                </div>
                <div class="show-stack" (click)="message.errorInfo.showStack = !message.errorInfo.showStack">
                  Show <span *ngIf="!message.errorInfo.showStack">more</span><span *ngIf="message.errorInfo.showStack">less</span>..
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="message.alertInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">name</span>
                =
                '{{message.alertInfo.name}}'
              </div>
              <div>
                <span class="highlight-text">type</span>
                =
                '{{message.alertInfo.type}}'
              </div>
            </div>
          </div>
          <div *ngIf="message.goalInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">name</span>
                =
                '{{message.goalInfo.name}}'
              </div>
              <div>
                <span class="highlight-text">type</span>
                =
                '{{message.goalInfo.type}}'
              </div>
            </div>
          </div>
          <div *ngIf="message.pluginInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">operation</span>
                =
                '{{message.pluginInfo.operationName}}'
              </div>
              <div *ngFor="let parameter of message.pluginInfo.parameters" class="plugin-parameters">
                <div>
                  <span class="highlight-text">parameter</span> =
                  '{{parameter.name}}'
                </div>
                <div>
                  <span class="highlight-text">optional</span> =
                  '{{parameter.isOptional}}'
                </div>
                <div>
                  <span class="highlight-text">value</span> =
                  '{{parameter.value}}'
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="message.conditionInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">condition</span>
                =
                '{{message.conditionInfo.condition}}'
              </div>
              <div>
                <span class="highlight-text">match</span>
                =
                '{{message.conditionInfo.match}}'
              </div>
              <div>
                <span class="highlight-text">action</span>
                =
                <span *ngIf="message.conditionInfo.action">Trigger dialog: '{{message.conditionInfo.action}}'</span>
                <span *ngIf="!message.conditionInfo.action">'Continue'</span>
              </div>
            </div>
          </div>
          <div *ngIf="message.notificationInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">Notification</span>
                =
                '{{message.notificationInfo.payload }}'
              </div>
            </div>
          </div>
          <div *ngIf="message.spellCheckInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">Query</span>
                =
                '{{message.spellCheckInfo.query }}'
              </div>
              <div>
                <span class="highlight-text">Result</span>
                =
                '{{message.spellCheckInfo.result }}'
              </div>
            </div>
          </div>
          <div *ngIf="message.languageDetectedInfo">
            <div class="message-expanded-content">
              <div>
                <span class="highlight-text">Query</span>
                =
                '{{message.languageDetectedInfo.query }}'
              </div>
              <div>
                <span class="highlight-text">Result</span>
                =
                '{{message.languageDetectedInfo.result }}'
              </div>
            </div>
          </div>
          <div *ngIf='message.variables?.length > 0' class="insp-selected">Variables
              <mat-icon [inline]="true" (click)="openVariable(message, true)" class="message-icon"
              [ngClass]="message.variablesOpen ? 'insp-selected' : ''"> {{ message.variablesOpen ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
          </div>
          <div *ngIf='message.variablesOpen' class="variables-expanded">
              <div>
                  <div class="message-expanded-content">
                      <div *ngFor="let variable of message.variables">
                        <br>
<!--                        <div><span class="highlight-text">{{variable.internalName}}</span> = {{variable.value}}</div>-->
<!--                        <div *ngIf="variable.normalizedValue"><span class="highlight-text">{{variable.normalizedValue}}</span> = {{variable.normalizedValue}}</div>-->
                        <div>
                          <div>
                            <span class="highlight-text">Name</span> : {{variable.internalName}}
                          </div>
                          <div>
                            <span class="highlight-text">Value</span> : {{variable.value}}
                          </div>
                          <div *ngIf="variable.normalizedValue">
                            <span class="highlight-text">Normalized value</span> : {{variable.normalizedValue}}
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <!--test-->
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
  </mat-card-content>
</mat-card>
