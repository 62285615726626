import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

// RxJs
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

// NgRx
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'app/auth/store';
import * as fromRoot from 'app/core/store';


@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {

  public isTestEnvironment$: Observable<boolean>;

  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>) {
      this.isTestEnvironment$ =  this.store.pipe(select(fromAuth.isTestEnvironment));
    }

  canActivate(route: ActivatedRouteSnapshot):Observable<boolean> {
   return this.isTestEnvironment$.pipe(
      filter(flag => flag != null),
      first(),
      map(isTestEnvironment => {
        if (!isTestEnvironment &&
          route.data['redirectReadOnly'] &&
          route.data['redirectReadOnly'] === true) {
          this.router.navigateByUrl('/');
          return false;
        }
        return true;
      }));
  }

}
