import { TwoFactorType } from 'app/domain/dtos/configuration/TwoFactorType';
import * as authActions from './auth.actions';
import { createReducer, on } from '@ngrx/store';
import { UserBot } from 'app/domain/dtos/configuration/UserBot';
import { BotEnvironments } from 'app/domain/constants/BotEnvironments';

export interface AuthState {
  botId: number;
  environment: string;
  userId: string;
  username: string;
  errorMessage: string;
  isPrelimnary: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  userBots: UserBot[];
  permissions: string[];
  firstname: string;
  lastname: string;
  alias: string;
  isActive: boolean;
  accountType: string;
  expires: number;
  picture: string;
  isBotCreated: boolean;
  email: string;
  token: string;
  twoFaType: TwoFactorType;
  navigationUrl: string;
  twoFAToken: string;
  phoneNumber: string;
}

export const initialState: AuthState = {
  botId: null,
  environment: BotEnvironments.test,
  userId: '',
  username: '',
  errorMessage: '',
  isPrelimnary: true,
  isAuthenticating: false,
  isAuthenticated: false,
  userBots: [],
  permissions: [],
  isActive: false,
  firstname: '',
  lastname: '',
  alias: '',
  accountType: '',
  expires: null,
  picture: '',
  email: '',
  twoFaType: null,
  navigationUrl: '',
  twoFAToken: '',
  token: null,
  phoneNumber: '',
  isBotCreated: null
};

export const authReducer = createReducer(
  initialState,
  on(authActions.loadFromSessionStorageSuccess, (state, { payload }) => ({
    ...state,
    ...payload.userObject,
    ...payload.appSettings
  })),
  on(authActions.login, (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    errorMessage: ''
  })),
  on(authActions.loginWithAzureToken, (state, {}) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    errorMessage: '',
    accountType: 'AzureAD'
  })),
  on(authActions.refreshAzureToken, (state, {}) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    errorMessage: '',
    accountType: 'AzureAD'
  })),
  on(authActions.refreshTokenRedirectMSAL, (state, {}) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    errorMessage: '',
    accountType: 'AzureAD'
  })),
  on(authActions.loginSuccess, (state, { payload }) => ({
    ...state,
    ...payload.userObject,
    ...payload.appSettings,
    isAuthenticated: true,
    isAuthenticating: false,
    errorMessage: ''
  })),

  on(authActions.navigateToTwoFAURL, (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    email: payload.email,
    navigationUrl: payload.navigationUrl,
    phoneNumber: payload.phoneNumber
  })),

  on(authActions.confirmTwoFa, (state, { token }) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    twoFAToken: token
  })),

  on(authActions.generateTwoFAToken, (state, { payload }) => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true,
    twoFaType: payload.type,
    email: payload.email
  })),

  on(authActions.loginFail, (state, { errorMessage }) => ({
    ...state,
    isPrelimnary: false,
    isAuthenticated: false,
    isAuthenticating: false,
    errorMessage
  })),
  on(authActions.navigateToRedirectURL, (state, {}) => ({
    ...state,
    isPrelimnary: false
  })),
  on(authActions.setBotId, (state, { payload }) => ({
    ...state,
    botId: payload.botId
  })),
  on(authActions.setEnvironment, (state, { payload }) => ({
    ...state,
    environment: payload.environment
  })),
  on(authActions.setBotActive, (state, { payload }) => ({
    ...state,
    isBotActive: payload.isBotActive
  })),
  on(authActions.forgotPassword, (state, { payload }) => ({
    ...state,
    email: payload.email
  })),
  on(authActions.resetPassword, (state, { payload }) => ({
    ...state
  })),
  on(authActions.reset, state => ({
    ...initialState,
    isPrelimnary: false
  })),
  on(authActions.noop, state => ({
    ...state,
    isAuthenticating: false,
    isAuthenticated: false
  }))
);
