import { Component, HostListener, Input } from '@angular/core';
import { BotInfoResponse } from 'app/domain/dtos/configuration/BotInfoResponse';
import { WebChatTokenIssueResponse } from 'app/domain/dtos/conversation/WebChatTokenIssueResponse';


@Component({
  selector: 'app-bot-detail',
  templateUrl: './bot-detail.component.html',
  styleUrls: ['./bot-detail.component.scss']
})
export class BotDetailChatCardComponent{
  
  public contentHidden: boolean = false;

  @Input() botInfoResponse: BotInfoResponse;

  hideContent() {
    this.contentHidden = !this.contentHidden;
  }

}
