import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

// Root Singleton Services
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { LoginResponseObj } from '../../domain/loginResponseObj';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolesService } from '../../core/services/roles.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  currentUser: LoginResponseObj;
  userObjectKey = 'userObject';

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    public snackbar: MatSnackBar,
    private userRoleService: RolesService
  ) {
    const userObject = new LoginResponseObj();
    const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
    this.currentUser = userObject.parseFromSessionStorage(sessionStorageItem);
  }

  canActivate(): Observable<boolean> {
    if (this.currentUser && this.currentUser.userId) {
      return this.userRoleService.isAdminOrEboAdmin(this.currentUser.userId);
    } else {
      this.router.navigate(['/settings/view']);
      this.snackbar.open(`Forbidden: You don't have access to this page. Admin or EboAdmin is needed.`, 'Dismiss', { duration: 3000 });
      return of(false);
    }
  }
}
