import * as moment from 'moment';
import { SpellCheckInfo } from './dtos/configuration/SpellCheckInfo';
import { StateInputVariable } from './dtos/configuration/StateInputVariable';

export class InspectorMessage {
  name: string;
  date: string;
  processingTime: string;
  nlpEngineName: string;
  nlpMessage: InspectorNlpMessage;
  nlpMatchInfo: InspectorNlpMatchMessage;
  dialogInfo: InspectorDialogMessage;
  errorInfo: InspectorErrorMessage;
  alertInfo: InspectorAlertMessage;
  goalInfo: InspectorGoalMessage;
  pluginInfo: InspectorPluginMessage;
  retryInfo: InspectoreRetryMessage;
  conditionInfo: InspectorConditionMessage;
  notificationInfo: InspectorNotificationMessage;
  spellCheckInfo: InspectorSpellCheckMessage;
  variables:StateInputVariable[];
  variablesOpen:boolean;
  icon: string;
  languageDetectedInfo: InspectorLanguageDetectionInfo;
  hubMessageId: string;

  parse(message: any): InspectorMessage {

    this.hubMessageId = message.hubMessageId;
    this.variables = message.variables;
    this.variablesOpen = false;
    this.date = moment.utc(message.currentUtcTime).local().format('DD/MM/YYYY HH:mm:ss');
    if (message.processingTime != 0) {
      this.processingTime = message.processingTime + ' ms';
    }


    if (message.nlpInfo) {
      this.icon = "swap_calls";
      this.nlpMessage = new InspectorNlpMessage().parse(message.nlpInfo);
      this.name = `NLP (${this.nlpMessage.nlpEngineName}) response received`;
    }

    if (message.nlpMatchInfo) {
      this.name = "NLP Matches dialog";
      this.icon = "adjust";
      this.nlpMatchInfo = new InspectorNlpMatchMessage().parse(message.nlpMatchInfo);
    }

    if (message.dialogInfo) {
      this.dialogInfo = new InspectorDialogMessage().parse(message.dialogInfo);
      if (this.dialogInfo.isSecure && this.dialogInfo.isLoggedIn) {
        this.icon = "lock_open";
        this.name = "Secure dialog triggered";
      }
      else if (this.dialogInfo.isSecure && !this.dialogInfo.isLoggedIn) {
        this.icon = "lock";
        this.name = "Login required for dialog";
      }
      else {
        this.icon = "chat";
        if (this.dialogInfo.dialogType == 0)/*=default*/ {
          this.name = "User dialog triggered";
        }
        else {
          this.name = `${this.dialogInfo.dialogTypeName} dialog triggered`;
        }
      }
    }

    if (message.errorInfo) {
      this.name = "An exception occured";
      this.icon = "error";
      this.errorInfo = new InspectorErrorMessage().parse(message.errorInfo);
    }

    if (message.alertInfo) {
      this.name = `Alert triggered`;
      this.icon = "notifications";
      this.alertInfo = new InspectorAlertMessage().parse(message.alertInfo);
    }

    if (message.goalInfo) {
      this.name = `Goal triggered`;
      this.icon = "chat";
      this.goalInfo = new InspectorGoalMessage().parse(message.goalInfo);
    }

    if (message.pluginInfo) {
      this.name = `Plugin ${message.pluginInfo.pluginName} triggered`;
      this.icon = "call_split";
      this.pluginInfo = new InspectorPluginMessage().parse(message.pluginInfo);
    }

    if (message.retryInfo) {
      this.retryInfo = new InspectoreRetryMessage().parse(message.retryInfo);

      if (this.retryInfo.retries <= this.retryInfo.maxRetries) {
        this.name = `Retry attempt #${message.retryInfo.retries} of #${message.retryInfo.maxRetries} triggered`;
      }
      else {
        this.name = "Retry attempts exceeded";
      }
      this.icon = "refresh";
    }

    if (message.conditionInfo) {
      this.name = `Condition triggered`;
      this.icon = "chat";
      this.conditionInfo = new InspectorConditionMessage().parse(message.conditionInfo);
    }

    if (message.notificationInfo) {
      this.name = "Incoming notification";
      this.icon = "forward";
      this.notificationInfo = new InspectorNotificationMessage().parse(message.notificationInfo);
    }

    if (message.spellCheckInfo) {
      this.name = "Spellcheck";
      this.icon = "spellcheck";
      this.spellCheckInfo = new InspectorSpellCheckMessage().parse(message.spellCheckInfo);
    }

    if (message.languageDetectedInfo) {
      this.name = "Language Detected";
      this.icon = "swap_calls";
      this.languageDetectedInfo = new InspectorLanguageDetectionInfo().parse(message.languageDetectedInfo);
    }

    return this;
  }
}

export class InspectorNlpMatchMessage {
  dialogId: number;
  dialogName: string;
  intentExpression: string;
  entityExpression: string;

  parse(nlpMatchInfo: any): InspectorNlpMatchMessage {
    if (nlpMatchInfo == null) { return; }

    this.dialogId = nlpMatchInfo.dialogId;
    this.dialogName = nlpMatchInfo.dialogName;
    this.intentExpression = nlpMatchInfo.intentExpression;
    this.entityExpression = nlpMatchInfo.entityExpression;
    return this;
  }
}

export class InspectorNlpMessage {
  topScoringIntentName: string;
  topScoringIntentScore: string;
  sentimentAnalysisLabel: string;
  sentimentAnalysisScore: string;
  entities: Array<InspectorEntity>;
  nlpEngineName: string;

  parse(nlpInfo: any): InspectorNlpMessage {
    this.nlpEngineName = nlpInfo.nlpEngineName;

    if (nlpInfo.sentimentAnalysis) {
      this.sentimentAnalysisLabel = nlpInfo.sentimentAnalysis.label;
      this.sentimentAnalysisScore = nlpInfo.sentimentAnalysis.score;
    }

    if (nlpInfo.topScoringIntent) {
      this.topScoringIntentName = nlpInfo.topScoringIntent.name;
      this.topScoringIntentScore = nlpInfo.topScoringIntent.score;
    }

    if (nlpInfo.entities) {
      this.entities = new Array<InspectorEntity>();
      nlpInfo.entities.forEach(entity => {
        this.entities.unshift(new InspectorEntity().parse(entity));
      });
    }

    return this;
  }
}

export class InspectorPluginParameter {
  name: string;
  isOptional: boolean;
  value: string;

  parse(parameter: any): InspectorPluginParameter {
    this.name = parameter.name;
    this.isOptional = parameter.isOptional;
    this.value = parameter.value;
    return this;
  }
}

export class InspectoreRetryMessage {
  dialogId: number;
  dialogName: string;
  retries: number;
  maxRetries: number;

  parse(retryInfo: any): InspectoreRetryMessage {
    this.dialogId = retryInfo.dialogId;
    this.dialogName = retryInfo.dialogName;
    this.retries = retryInfo.retries;
    this.maxRetries = retryInfo.maxRetries;

    return this;
  }
}

export class InspectorPluginMessage {
  pluginName: string;
  operationName: string;
  parameters: Array<InspectorPluginParameter>;

  parse(pluginInfo: any): InspectorPluginMessage {
    this.pluginName = pluginInfo.pluginName;
    this.operationName = pluginInfo.operationName;

    if (pluginInfo.parameters) {
      this.parameters = new Array<InspectorPluginParameter>();
      pluginInfo.parameters.forEach(parameter => {
        this.parameters.unshift(new InspectorPluginParameter().parse(parameter));
      });
    }

    return this;
  }
}

export class InspectorErrorMessage {
  errorMessage: string;
  stackTrace: string;

  parse(errorInfo: any): InspectorErrorMessage {
    this.errorMessage = errorInfo.errorMessage;
    this.stackTrace = errorInfo.stackTrace;
    return this;
  }
}

export class InspectorAlertMessage {
  name: string;
  type: string;

  parse(alertInfo: any): InspectorAlertMessage {
    this.name = alertInfo.name;
    this.type = alertInfo.type;
    return this;
  }
}

export class InspectorGoalMessage {
  name: string;
  type: string;

  parse(goalInfo: any): InspectorGoalMessage {
    this.name = goalInfo.name;
    this.type = goalInfo.type;
    return this;
  }
}

export class InspectorEntity {
  name: string;
  score: string;
  type: string;
  value: string;
  normalizedValue: string;
  parse(entity: any): InspectorEntity {
    this.name = entity.name;
    this.score = entity.score;
    this.type = entity.type;
    this.value = entity.value;
    this.normalizedValue = entity.normalizedValue;
    return this;
  }
}

export class InspectorDialogMessage {
  dialogId: string;
  dialogName: string;
  dialogType: number;
  dialogTypeName: string;
  isSecure: boolean;
  isLoggedIn: boolean;

  parse(dialogInfo: any): InspectorDialogMessage {
    this.dialogId = dialogInfo.dialogId;
    this.dialogName = dialogInfo.dialogName;
    this.dialogType = dialogInfo.dialogType;
    this.dialogTypeName = dialogInfo.dialogTypeName;
    this.isSecure = dialogInfo.isSecure;
    this.isLoggedIn = dialogInfo.isLoggedIn;

    return this;
  }
}

export class InspectorConditionMessage {
  condition: string;
  match: string;
  action: string;

  parse(conditionInfo: any): InspectorConditionMessage {
    this.condition = conditionInfo.condition;
    this.match = conditionInfo.match;
    this.action = conditionInfo.action;
    return this;
  }
}

export class InspectorNotificationMessage {
  payload: string;

  parse(notificationInfo: any): InspectorNotificationMessage {
    this.payload = notificationInfo.payload;
    return this;
  }
}

export class InspectorSpellCheckMessage {
  query: string;
  result: string;

  parse(spellCheckInfo: any): InspectorSpellCheckMessage {
    this.query = spellCheckInfo.query;
    this.result = spellCheckInfo.result;
    return this;
  }
}

export class InspectorLanguageDetectionInfo {
  query: string;
  result: string;

  parse(languageDetectedInfo: any): InspectorLanguageDetectionInfo {
    this.query = languageDetectedInfo.query;
    this.result = languageDetectedInfo.result;
    return this;
  }
}
