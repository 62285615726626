<div mat-dialog-title class="ebo-dialog-title">
  <h5 class="m-0 font-normal"><strong>Reset your password</strong></h5>
  <button class="close-button" mat-icon-button matTooltip="Discard changes" (click)="cancel()">
    <mat-icon class="ebo-icon" aria-label="">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="ebo-dialog-content" id="reset-password">
  <div [ngClass]="{
    'ebo-dialog-form': true,
    'custom-fields': resetOwnPassword == 0
  }">
    <div fxLayout="column">
      <ng-container *ngIf="data">
        <mat-form-field>
          <mat-label>Your email</mat-label>
          <input matInput type="email" formControlName="email">
        </mat-form-field>
      </ng-container>
      <div fxFlex>
        <button color="primary" mat-raised-button matTooltip='Reset your password manually' (click)='changeFields(0)'>
          Reset password
        </button>
        OR
        <button color="primary" mat-raised-button matTooltip='Generate new password' (click)='changeFields(1)'>
          Generate password
        </button>
      </div>
      <ng-container *ngIf="hasBeenOpened">
        <ng-container *ngIf="resetOwnPassword == 0">
          <div fxFlex class="mt-2" [formGroup]="ownPasswordForm">
            <mat-form-field>
              <mat-label>Enter your new password</mat-label>
              <input matInput type="password" formControlName="password">
              <mat-hint align="end">Must contain at least: 8 up to 15 characters, one capital letter and one special character</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Confirm your new password</mat-label>
              <input matInput type="password" formControlName="confirmPassword">
            </mat-form-field>
            <mat-error *ngIf="ownPasswordForm.get('password').hasError('required')">Password is required</mat-error>
            <mat-error *ngIf="ownPasswordForm.get('password').hasError('minlength')">Password must have at least 8 characters</mat-error>
            <mat-error *ngIf="ownPasswordForm.get('password').hasError('maxlength')">Password must not exceed 15 characters.</mat-error>
            <mat-error *ngIf="ownPasswordForm.get('password').hasError('invalidPassword')">The password must contain at least one capital letter and one special character.</mat-error>
            <mat-error *ngIf="ownPasswordForm.get('password').valid && ownPasswordForm.get('confirmPassword')?.value?.length > 2 && ownPasswordForm.hasError('passwordMismatch')">Passwords don't match</mat-error>
          </div>
        </ng-container>
        <ng-container *ngIf="resetOwnPassword == 1">
          <div fxFlex class="mt-2" [formGroup]="generatedForm">
            <mat-form-field appearance="outline" *ngIf="generatedForm.get('generatedPassword').value">
              <mat-label>New Generated Password</mat-label>
              <input readonly="true" matInput formControlName="generatedPassword"/>
            </mat-form-field>
            <button color="primary" mat-raised-button (click)='generate()' *ngIf="!generatedForm.get('generatedPassword').value">
              Generate
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="ebo-dialog-actions">
  <button  mat-raised-button [disabled]="!ownPasswordForm.valid || !ownPasswordForm.get('password').value" color="primary" (click)="save()" *ngIf="resetOwnPassword == 0">Save</button>
  <button  mat-raised-button [disabled]="!formFromSignIn.valid || !formFromSignIn.get('password').value" color="primary" (click)="save()" *ngIf="data">Save</button>
  <button mat-button class="ebo-button-normal" (click)="cancel()" [matTooltip]="resetOwnPassword === 1 ? 'Do not forget to copy your password' : ''">Close</button>
</mat-dialog-actions>
