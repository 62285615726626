export class UserAppSettings {
  environment: string;
  botId: number;
  urlLoaded?: string;

  parseFromSessionStorage(input: any) {
    if (!input) return;
    input = JSON.parse(input);
    this.environment = input.environment;
    this.botId = input.botId;
    return this;
  }
}
