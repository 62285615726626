/**
 * Prefix and suffix variables with 2 percentages. We replace these values during deployment
 */
export const environment = {
  production: true,
  apiUrl: '%%productionApi%%',
  appVersion: '%%version%%',
  conversationApi: '%%conversationApi%%',
  externalApi: '%%externalTableApi%%',
  clientId: '%%productionClientId%%',
  xApiKey: '%%productionXApiKey%%',
  webchatFileName: '%%webchatFileName%%'
};
