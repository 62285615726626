import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPermissionsService } from '../../core/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fromAuth from '../store';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  private isTestEnvironment$: Observable<boolean>;
  private isTestEnvironment: boolean;

  constructor(
    private permissionsService: UserPermissionsService,
    private router: Router,
    private snackbar: MatSnackBar,
    private store: Store<fromRoot.AppState>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredPermission = next.data.permission;
    this.isTestEnvironment$ = this.store.select(fromAuth.isTestEnvironment);
    this.isTestEnvironment$.subscribe(res => {
      this.isTestEnvironment = res;
    });

    if (this.permissionsService.hasPermission(requiredPermission)) {
      return true;
    } else {
      this.snackbar.open('You do not have permission to access this page', 'Close', {
        duration: 3000
      });
      if (this.isTestEnvironment) {
        this.router.navigate(['/test/reports/view']);
        return false;
      } else {
        this.router.navigate(['/prod/reports/view']);
        return false;
      }
    }
  }
}
