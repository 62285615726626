import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { LoginResponseObj } from 'app/domain/loginResponseObj';
import { UsersService } from 'app/views/users/users.service';
import { Observable } from 'rxjs';

@Directive({
  selector: '[eboAccessByRole]'
})
export class AccessByRoleDirective implements OnInit {
  @Input() eboAccessByRole: string[] | string;
  currentUser: LoginResponseObj;
  roles: Observable<string[]>;
  userObjectKey = 'userObject';

  constructor(private elementRef: ElementRef, private userService: UsersService, private sessionStorage: SessionStorageService) {}

  ngOnInit(): void {
    this.userService.cancelPendingRequests();
    let comparisonArray: string[] = [];
    const userObject = new LoginResponseObj();
    const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
    this.currentUser = userObject.parseFromSessionStorage(sessionStorageItem);
    this.roles = this.userService.getUserRoles(this.currentUser.userId);
    if (Array.isArray(this.eboAccessByRole)) {
      comparisonArray = this.eboAccessByRole;
    } else {
      comparisonArray.push(this.eboAccessByRole);
    }

    this.roles.subscribe(x => {
      if (!comparisonArray.some(y => x['roles'].includes(y)) || this.currentUser == null) {
        this.elementRef.nativeElement.style.display = 'none';
        return;
      } else {
        // TODO: Check if there is no css broken without this line
        // this.elementRef.nativeElement.style.display = 'block';
        return;
      }
    });
  }
}
