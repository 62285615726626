import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

// Root Singleton Service
import { NavigationService } from "app/core/services";

@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html'
})
export class SidebarRightComponent implements OnInit {
  @ViewChild("botWindow") botWindowElement: ElementRef;

  constructor(public navService: NavigationService) { }

  ngOnInit() {
  }

  openSidebarRight(): void {
    if (this.navService.sidebarRightOpen == true) {
      this.navService.sidebarRightOpen = false;
    } else {
      this.navService.sidebarRightOpen = true;
      this.navService.sidebarRightOpened = true;
    }
  }

}
