

export class ResetPasswordRequest {
    email: string;
    password: string;
    confirmPassword: string;
    code?: string;

    constructor() {



   }
}


