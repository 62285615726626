import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RxJs
import { Observable } from 'rxjs';

// App
import { LoginRequest } from 'app/domain/dtos/configuration/LoginRequest';

// Root Singleton Services
import { ApiService } from 'app/core/services/api.service';
import { HttpClientService } from 'app/core/services/http-client.service';
import { AuthResponse } from 'app/domain/dtos/configuration/AuthResponse';
import { Router } from '@angular/router';
import { ForgotPasswordRequest } from 'app/domain/dtos/configuration/ForgotPasswordRequest';
import { ResetPasswordRequest } from 'app/domain/dtos/configuration/ResetPasswordRequest';
import { TwoFactorAuthenticationRequest } from 'app/domain/dtos/configuration/TwoFactorAuthenticationRequest';
import { TwoFactorResponse } from 'app/domain/dtos/configuration/TwoFactorResponse';
import { ConfirmTwoFARequest } from 'app/domain/dtos/configuration/ConfirmTwoFARequest';
import { ResetModel } from '../store/auth.effects';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  redirectUrl: string;
  redirectUrlParams: any;
  apiUrl: string;
  httpOptions;

  constructor(private http: HttpClientService, private apiService: ApiService, private router: Router) {
    this.apiUrl = `${this.apiService.apiUrl}`;
  }

  logout() {
    this.router.navigate(['/sessions/signin']);
  }

  login(username: string, password: string): Observable<AuthResponse> {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    var request = new LoginRequest();
    request.username = username;
    request.password = password;
    return this.http.post<AuthResponse>(`${this.apiUrl}oauth/token`, request, true);
  }

  loginWithAzureToken(): Observable<AuthResponse> {
    return this.http.get<AuthResponse>(`${this.apiUrl}oauth/azure/token`, true, true);
  }

  refreshToken(): Observable<AuthResponse> {
    this.redirectUrl = null;
    return this.http.get<AuthResponse>(`${this.apiUrl}oauth/token/refresh`, true);
  }

  forgotPassword(email: string): Observable<boolean> {
    var request = new ForgotPasswordRequest();
    request.email = email;
    return this.http.post<boolean>(`${this.apiUrl}oauth/forgotpassword`, request, true);
  }

  resetPassword(request: ResetPasswordRequest): Observable<ResetModel> {
    return this.http.post<ResetModel>(`${this.apiUrl}oauth/resetpassword`, request, true);
  }

  twoFAMethod(request: TwoFactorAuthenticationRequest): Observable<TwoFactorResponse> {
    return this.http.post<TwoFactorResponse>(`${this.apiUrl}oauth/twofa/generate`, request, true);
  }

  confirmTwoFA(request: ConfirmTwoFARequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${this.apiUrl}oauth/twofa`, request, true);
  }

  generateUserCode(request: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}oauth/generateusercode?email=${request}`, true);
  }
}
