import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagService } from '../../views/settings/feature-flag/services/feature-flag.service';

@Directive({
  selector: '[eboActiveFeature]'
})
export class ActiveFeatureDirective implements OnInit{
  @Input('eboActiveFeature') featureName: string;
  constructor(private el: ElementRef, private featureFlagService: FeatureFlagService) {
    this.featureName = '';
  }

  ngOnInit(): void {
    this.featureFlagService.getFeatureFlags().subscribe(data => {
      const feature = data.find(x => x.key === this.featureName);
      if (feature && feature.value === 'true') {
        this.el.nativeElement.style.display = 'block';
      } else {
        this.el.nativeElement.style.display = 'none';
      }
    });
  }

}
