import { Injectable, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

interface ITheme {
  name: string,
  baseColor?: string,
  isActive?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public egretThemes :ITheme[]  = [{
    "name": "ebo-theme",
    "baseColor": "#009688",
    "isActive": true
  }];
  public activatedTheme: ITheme;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme(r: Renderer2) {
    /*
    ****** (SET YOUR DEFAULT THEME HERE) *******
    * Assign new Theme to activatedTheme
    */
    // this.activatedTheme = this.egretThemes[0]; 
    // this.activatedTheme = this.egretThemes[1]; 
    // this.activatedTheme = this.egretThemes[2]; 
    this.activatedTheme = this.egretThemes[0];

    this.changeTheme(r, this.activatedTheme)
  }

  changeTheme(r: Renderer2, theme:ITheme) {
    r.removeClass(this.document.body, this.activatedTheme.name);
    r.addClass(this.document.body, theme.name);
    this.flipActiveFlag(theme)
  }
  flipActiveFlag(theme:ITheme) {
    this.egretThemes.forEach((t) => {
      t.isActive = false;
      if(t.name === theme.name) {
        t.isActive = true;
        this.activatedTheme = theme;
      }
    });
  }
}
