import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowdownModule } from 'ngx-showdown';

// 3rd
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// App
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { rootRouterConfig } from './app.routing';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared/material.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    CoreModule,
    MaterialModule,

    RouterModule.forRoot(rootRouterConfig,
       { useHash: false,
      //  enableTracing: true
      }),
    ShowdownModule.forRoot({
      flavor: 'github',
      underline: true,
      emoji: true
    })
  ],
  declarations: [AppComponent],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
