// 1. if you want your component code to be testable, it is best to use a route resolver and make the connection there
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
// @ts-ignore
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
// RxJs
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConnectionResolver {
  public conversationHub: HubConnection | undefined;
  public closed = new BehaviorSubject(false);
  // Our Behavior subject Initialize Connected - If is not connected => true
  public conversationHubStatus$ = new BehaviorSubject(false);

  public startconnection(authToken: string): void {
    this.conversationHub = new HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/hubs/conversations`, {
        accessTokenFactory: () => {
          return authToken;
        },
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    if (this.conversationHub || this.conversationHub.state === 'Disconnected' || this.conversationHubStatus$.value) {
      this.startHub();

      this.conversationHub.onclose(() => {
        if (!this.closed.value) {
          this.startHub();
        }
      });
    }
  }

  startHub(): void {
    this.conversationHub.start().then(
      () => {
        this.conversationHubStatus$.next(false);
      }
    ).catch(
      (err) => {
        console.log('Error on conversationHub: ', err);
        this.conversationHubStatus$.next(true);
      }
    );
  }

  closeHub(): void {
    this.conversationHub.stop().then(() => {
      this.closed.next(true);
      console.log('Connection closed');
    });
  }
}
