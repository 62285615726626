

export class UserBot {
    name: string;
    id: number;

    constructor() { 
   
   }
}

    
