<div class="app-admin-wrap" [dir]='layoutConf.dir'>

  <!-- Main Container -->
  <mat-sidenav-container
  [dir]='layoutConf.dir'
  class="app-admin-container app-side-nav-container mat-drawer-transition"
  [ngClass]="{
    'navigation-top': layoutConf.navigationPos === 'top',
    'sidebar-full': layoutConf.sidebarStyle === 'full',
    'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
    'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
    'layout-intransition': layoutConf.layoutInTransition,
    'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
    'sidebar-closed': layoutConf.sidebarStyle === 'closed',
    'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side'
  }">
  <!-- SIDEBAR -->
  <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
  <ebo-sidebar-side *ngIf="layoutConf.navigationPos === 'side' && (isBotCreated$ | async)"></ebo-sidebar-side>

    <!-- App content -->
    <div id="main-content-wrap" class="main-content-wrap" (scroll)="onMainContentScroll($event)" [class.full-width]="!(this.isBotCreated$ | async)" [class.test]="(this.environment$ | async) == 'Test'">
      <!-- Header for side navigation layout -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->


      <ebo-header-side
        [notificPanel]="notificationPanel">
      </ebo-header-side>

      <div [ngClass]="isFullHeight ? 'rightside-content-hold rightside-content-hold-full' : 'rightside-content-hold'">
        <!-- View Loader -->
        <div class="view-loader" *ngIf="isModuleLoading">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>

        <!-- View outlet -->
        <router-outlet></router-outlet>

      </div>
    </div>

    <!-- Notification bar -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end">
      <div class="navigation-hold" fxLayout="column">
        <ebo-notifications></ebo-notifications>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
