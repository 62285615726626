import {AuthResponse} from "app/domain/dtos/configuration/AuthResponse";
import {UserBot} from './dtos//configuration/UserBot';

export class LoginResponseObj extends AuthResponse {
    parseFromApi(input: any) {
        if (!input) return;
        this.userId = input.userId;
        this.username = input.username;
        this.firstname = input.firstname;
        this.lastname = input.lastname;
        this.alias = input.alias;
        this.email = input.email;
        this.picture = input.picture;
        this.isActive = input.isActive;
        this.isBotCreated = input.isBotCreated;
        this.token = input.token;
        this.expires = input.expires;
        this.permissions = input.permissions;
        this.accountType = input.accountType;
        this.userBots = this.parseUserbots(input.userBots);
        return this;
    }

    parseFromSessionStorage(key: any) {
        if (!key) return;
        key = JSON.parse(key);
        this.userId = key.userId;
        this.username = key.username;
        this.firstname = key.firstname;
        this.lastname = key.lastname;
        this.alias = key.alias;
        this.email = key.email;
        this.picture = key.picture;
        this.isActive = key.isActive;
        this.isBotCreated = key.isBotCreated;
        this.token = key.token;
        this.expires = key.expires;
        this.permissions = key.permissions;
        this.userBots = key.userBots;
        this.accountType = key.accountType;
        return this;
    }

    private parseUserbots(apiUserBots: any[]) {
        if (!apiUserBots || apiUserBots.length == 0) {
            return;
        }

        var userBots = new Array<UserBot>();
        if (apiUserBots && apiUserBots.length > 0) {
            apiUserBots.forEach(e => {
                var userBot = new UserBot();
                userBot.id = e.id;
                userBot.name = e.name;
                userBots.push(userBot);
            });
        }
        return userBots;
    }
}
