import { TwoFactorType } from 'app/domain/dtos/configuration/TwoFactorType';
import { createAction, props } from '@ngrx/store';
import { LoginResponseObj } from 'app/domain/loginResponseObj';
import { UserAppSettings } from 'app/domain/userAppSettings';

export const loadFromSessionStorage = // for redirect
  createAction('[Auth] Load From Session Storage');

export const loadFromSessionStorageSuccess =
  createAction('[Auth] Load From Local Storage Success', props<{ payload: {userObject:LoginResponseObj,appSettings:UserAppSettings} }>());

export const login = createAction('[Auth Sessions] Login with credentials', props<{ payload: { username: string; password: string } }>());

export const generateTwoFAToken =
  createAction('[Auth Sessions] Generate Two FA Token', props<{payload: {type: TwoFactorType, email:string}}>());

export const confirmTwoFa =
  createAction('[Auth Sessions] Confirm the Two FA token', props<{token: string}>())

export const loginMSAL = // for redirect
  createAction('[Auth MSAL -> Azure] Login');

export const loginWithAzureToken =
  createAction('[Auth Azure -> Sessions] Login with Azure Token');

export const loginSuccess =
  createAction('[Auth Sessions/Azure] Login Success', props<{ payload: {
      userObject: LoginResponseObj,
      appSettings: UserAppSettings
    } }>());
export const refreshAzureToken =
  createAction('[Auth MSAL -> Azure] Refresh Azure Token');

export const refreshTokenRedirectMSAL =
  createAction('[Auth] Refresh Azure Token Redirect');

export const refreshToken =
  createAction('[Auth] Refresh Token');

export const logout =
  createAction('[Auth] Logout');

export const forgotPassword =
  createAction('[Auth] Forgot Password', props<{ payload: {email : string} }>());

export const forgotPasswordSuccess =
  createAction('[Auth] Forgot Password Success');

  export const forgotPasswordFail =
  createAction('[Auth] Forgot Password Fail');

export const resetPassword =
  createAction('[Auth] Reset Password', props<{ payload: {email: string; password: string; confirmPassword: string;code: string;} }>());

export const resetPasswordSuccess =
  createAction('[Auth] Reset Password Success');

  export const resetPasswordFail =
  createAction('[Auth] Reset Password Fail');

export const setBotId =
  createAction('[Auth] Set Bot Id', props<{ payload: {botId:number} }>());

export const setEnvironment =
  createAction('[Auth] Set Environment', props<{ payload: { environment: string } }>());

export const setBotActive =
  createAction('[Auth] Set Bot Active', props<{payload: { isBotActive: boolean} }>());

export const navigateToRedirectURL =
  createAction('[Auth] Navigate To Redirect URL');

export const navigateToTwoFAURL =
  createAction('[Auth] Navigate To Two FA URL', props<{payload:{email: string, navigationUrl: string, phoneNumber:string}}>());

export const loginFail =
  createAction('[Auth] Login Fail', props<{ errorMessage: string }>());

export const reset =
   createAction('[Auth] Reset');

export const setRedirectUrl =
   createAction('[Auth] Set Redirect URL', props<{ url: string }>());

export const noop =
  createAction('[Auth] No Operation');
