import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
// animations
import { animate, style, transition, trigger } from '@angular/animations';
// App
import { BroadcastEboInspectorFeedRequest } from 'app/domain/dtos/configuration/BroadcastEboInspectorFeedRequest';
import { InspectorMessage } from 'app/domain/inspectorMessage';

// Root Singleton Service
import { ConnectionResolver } from 'app/core/services';

// Meterial
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ebo-inspector',
  templateUrl: './ebo-inspector.component.html',
  styleUrls: ['./ebo-inspector.component.scss'],
  animations: [
    trigger('inspectorMessages', [
      transition(':enter', [
        style({ transform: 'scale(0.8)', opacity: 0 }), // initial
        animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })) // final
      ])
    ])
  ]
})
export class EboInspectorChatCardComponent implements OnInit, OnDestroy {
  @Input() userChatId: string;
  inspectorMessages = new Array<InspectorMessage>();

  constructor(public dialog: MatDialog, private connectionResolver: ConnectionResolver, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.connectionResolver.conversationHubStatus$.subscribe(status => {
      if (this.connectionResolver.conversationHub.state === 'Connected') {
        if (!status) {
          this.cdr.detectChanges();
          console.log('joinEboInspectorGroup');
          this.connectionResolver.conversationHub
            .invoke('joinEboInspectorGroup', this.userChatId)
            .catch(err => console.log('JoinEboInspectorGroup error : ', err));

          this.connectionResolver.conversationHub.on('EboInspectorFeed', (feedMessage: BroadcastEboInspectorFeedRequest) => {
            const message = new InspectorMessage().parse(feedMessage);
            this.inspectorMessages.unshift(message);

            this.inspectorMessages.sort((a: InspectorMessage, b: InspectorMessage) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            });

            this.cdr.detectChanges();

            // Assuming feedMessage includes a messageId property
            if (message.hubMessageId) {
              this.connectionResolver.conversationHub
                .invoke('AcknowledgeMessage', message.hubMessageId)
                .catch(err => console.error(`Error acknowledging message ${message.hubMessageId}: `, err));
            }
          });
        }
      }
    });
  }

  openVariable = (message, bool?: boolean) => {
    if (bool) {
      message.variablesOpen = !message.variablesOpen;
      this.cdr.detectChanges();
      return message.variablesOpen;
    } else {
      message.expanded = !message.expanded;
      this.cdr.detectChanges();
      return message.expanded;
    }
  };

  ngOnDestroy() {
    this.connectionResolver.conversationHubStatus$.subscribe(status => {
      if (this.connectionResolver.conversationHub.state === 'Connected') {
        if (!status) {
          this.cdr.detectChanges();
          console.log('leaveEboInspectorGroup');
          this.connectionResolver.conversationHub
            .invoke('leaveEboInspectorGroup', this.userChatId)
            .catch(err => console.log('LeaveEboInpectorGroup error: ', err));
        }
      }
    });
  }
}
