import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'environments/environment';
// MSAL
import {
    BroadcastService,
    MSAL_CONFIG,
    MSAL_CONFIG_ANGULAR,
    MsalAngularConfiguration,
    MsalModule,
    MsalService
} from '@azure/msal-angular';
import { Configuration, Logger, LogLevel } from 'msal';
import { MsalInterceptor } from './msal/msal.interceptor'; // Custom as microsoft's one is broken
// NgRx
import { Store } from '@ngrx/store';
import * as authActions from 'app/auth/store/auth.actions';
import * as fromRoot from 'app/core/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionsInterceptor } from './sessions/sessions.interceptor';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/common',
      validateAuthority: true,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      logger: new Logger(loggerCallback, {
          correlationId: '1234',
          level: LogLevel.Verbose,
          piiLoggingEnabled: true,
      }),
    }
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: [
      `api://${environment.clientId}/access_as_user`
    ],
    protectedResourceMap: [
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
      [`${environment.apiUrl}/oauth/azure/token`, [`api://${environment.clientId}/access_as_user`]]
    ],
    extraQueryParameters: {

    }
  };
}

export function loggerCallback(logLevel, message, piiEnabled) {
  // console.log(message);
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MsalModule
  ],
  declarations: [],
  providers: [
  {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<fromRoot.AppState>) => {
        return () => {
          store.dispatch(authActions.loadFromSessionStorage());
        };
      },
      multi: true,
      deps: [Store]
  },
  {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionsInterceptor,
      multi: true
  },
  {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
  },
  {
    provide: MSAL_CONFIG,
    useFactory: MSALConfigFactory
  },
  {
    provide: MSAL_CONFIG_ANGULAR,
    useFactory: MSALAngularConfigFactory
  },
  MsalService,
  BroadcastService
  ],
  exports: []
})
export class AuthModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: AuthModule,
    private broadcastService: BroadcastService,
    private snackbar: MatSnackBar,
    private store: Store<fromRoot.AppState>
  ) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import only in CoreModule');
    }
      // Means tenant of this account is not part of eboConfiguration--authentication--azureapp--validtenants
    this.broadcastService.subscribe("msal:notAuthorized",
      () => {
        // this.snackbar.open("Your company accounts are not authorized to access this System.", 'Close');
        this.store.dispatch(authActions.loginFail({
          errorMessage: 'Your account is not authorized. Try with a standard account or logout.'
        }));
      }
    );

  }

}
