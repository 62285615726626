import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiUrl: string;
    conversationApiUrl:string;
    externalApi: string;

    constructor() {
        this.apiUrl = this.setApiUrl(environment.apiUrl);
        this.conversationApiUrl = this.setApiUrl(environment.conversationApi);
        this.externalApi = environment.externalApi;
    }

    private setApiUrl(url) {
        if (url.substr(-1) != '/') { url += '/' };
        return url;
    }
}
