import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploadEvent } from './file-upload-event';
import { UploadService } from 'app/shared/services/upload.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ErrorMessage } from 'app/domain/errorMessage';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  constructor(private uploadService: UploadService) {}
  @Input() fileUrl = '';
  @Input() multiple = true;
  @Input() accept = '.jpg, .jpeg, .gif, .png, .svg';
  @Input() fileType = 'image';
  @Input() uploadImageButton = true;
  @Output() fileUploaded: EventEmitter<FileUploadEvent> = new EventEmitter();

  progress: number;
  errorMessage: string;
  totalFilesUploaded: number;
  files: any[];

  filesHandled = 0;

  ngOnInit() {
    if (this.fileType !== 'image' && this.fileType !== 'file') {
      throw new Error('file-upload apiPath is incorrect.');
    }
  }

  uploadFile(files: any) {
    this.files = files;
    this.filesHandled = 0;

    this.progress = null;
    if (files.length === 0) {
      return;
    }

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      if (this.fileType === 'image') {
        this.uploadService.uploadImage(formData).subscribe(
          (event: HttpEvent<any>) => {
            this.handleResponse(event);
          },
          (error: ErrorMessage) => {
            this.errorMessage = error.error.message;
            this.incrementFileCount();
          }
        );
      }

      if (this.fileType === 'file') {
        this.uploadService.uploadFile(formData).subscribe(
          (event: HttpEvent<any>) => {
            this.handleResponse(event);
          },
          (error: ErrorMessage) => {
            this.errorMessage = error.error.message;
            this.incrementFileCount();
          }
        );
      }
    }
  }
  private incrementFileCount() {
    this.filesHandled++;
  }

  handleResponse(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      this.progress = Math.round((100 * event.loaded) / event.total);
    } else if (event.type === HttpEventType.Response) {
      this.totalFilesUploaded++;
      const fileUploadEvent = new FileUploadEvent();
      fileUploadEvent.referenceId = event.body.referenceId;
      fileUploadEvent.url = event.body.url;
      this.fileUploaded.emit(fileUploadEvent);
      this.incrementFileCount();
    }
  }
}
