import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'app/core/store';
import { BotEnvironments } from 'app/domain/constants/BotEnvironments';
import * as fromAuth from './auth.reducer';

// Selectors
const getAuthState = createFeatureSelector<AppState, fromAuth.AuthState>('auth');

export const getAccountType = createSelector(
  getAuthState,
  state => state.accountType
);

export const getTwoFAType = createSelector(
  getAuthState,
  state => state.twoFaType
)

export const getNavigationUrl = createSelector(
  getAuthState,
  state => state.navigationUrl
)

export const getPhoneNumber = createSelector(
  getAuthState,
  state => state.phoneNumber
)

export const getTwoFAToken = createSelector(
  getAuthState,
  state => state.twoFAToken
)

export const getIsPrelimnary = createSelector(
  getAuthState,
  state => state.isPrelimnary
);

export const getIsAuthenticated = createSelector(
  getAuthState,
  state => state.isAuthenticated
);

export const getIsAuthenticating = createSelector(
  getAuthState,
  state => state.isAuthenticating
);

export const getErrorMessage = createSelector(
  getAuthState,
  state => state.errorMessage
);

export const getBotId = createSelector(
  getAuthState,
  state => state.botId
);

export const getEnvironment = createSelector(
  getAuthState,
  state => state.environment
);

export const getUserName = createSelector(
  getAuthState,
  state => state.username
);

export const getEmail = createSelector(
  getAuthState,
  state => state.email
);

export const getUserId = createSelector(
  getAuthState,
  state => state.userId
);

export const getActive = createSelector(
  getAuthState,
  state => state.isActive
);

export const getVirtualAgentName = createSelector(
  getAuthState,
  state => state.userBots.find(x => x.id == state.botId)?.name
);

export const getBots = createSelector(
  getAuthState,
  state => state.userBots
);

export const getFullName = createSelector(
  getAuthState,
  state =>  `${state.firstname} ${state.lastname}`
);


export const getFirstname = createSelector(
  getAuthState,
  state =>  state.firstname
);

export const getAlias = createSelector(
  getAuthState,
  state =>  state.alias
);

export const getExpires = createSelector(
  getAuthState,
  state =>  state.expires
);

export const getToken = createSelector(
  getAuthState,
  state =>  state.token
);

export const getPicture = createSelector(
  getAuthState,
  state => state.picture
);

export const hasPermission = createSelector(
  getAuthState,
  (state, props) => state.permissions.indexOf(props.permission) > -1
);

export const isTestEnvironment = createSelector(
  getAuthState,
  state => state.environment === BotEnvironments.test
);


export const isAzure = createSelector(
  getAuthState,
  state => state.accountType == 'AzureAD'
);


export const isBotCreated = createSelector(
  getAuthState,
  state => state.isBotCreated
);
