import { UserBot } from './UserBot';

export class AuthResponse {
    userId: string;
    token: string;
    firstname: string;
    lastname: string;
    email: string;
    picture: string;
    userBots: UserBot[];
    username: string;
    alias: string;
    isActive: boolean;
    isBotCreated: boolean;
    expires: number;
    permissions: string[];
    accountType: string;

    constructor() {





   this.userBots = new Array<UserBot>();







   }
}


