<button *ngIf="uploadImageButton" (click)="fileInput.click()" mat-icon-button
  style="display: inline-block; width: 30px">
  <mat-icon *ngIf="!fileUrl" style="font-size: 20px" class="ebo-icon">image</mat-icon>
  <img *ngIf="fileUrl" [src]="fileUrl" />
</button>

<div *ngIf="!uploadImageButton" style="display: block;position: relative;">
  <div>
    <button mat-raised-button color="primary" (click)="fileInput.click()">
      Select file(s)
    </button>
  </div>
  <div>
    <span *ngIf="files" style="position: absolute; right: 0px; top: 12px;">Uploaded {{ filesHandled }} of {{ files.length }} file(s)</span>
  </div>
</div>

<input #fileInput type="file" style="display: none" [attr.accept]="accept" placeholder="Upload image file"
  (change)="uploadFile(fileInput.files)" [attr.multiple]="multiple ? '' : null" />

<span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
  {{progress}}%
</span>
<span style="font-weight:bold;color:red;" *ngIf="errorMessage">
  {{errorMessage}}
</span>
