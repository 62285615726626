import { TwoFactorType } from './TwoFactorType';


export class ConfirmTwoFARequest {
    email: string;
    type: TwoFactorType;
    token: string;

    constructor() {


    }
}
