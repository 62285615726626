import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, } from '@angular/router';

// RxJs
import { Subscription } from "rxjs";
import { filter } from 'rxjs/operators';

// Root Singleton Service
import { LayoutService } from 'app/core/services';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  routeParts: any[];
  routerEventSub: Subscription;
  title: string;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    public layout: LayoutService
  ) {
    this.routerEventSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.title = this.activeRoute.snapshot?.firstChild?.data['title'] || this.activeRoute.snapshot?.children[0]?.firstChild?.data['title'];
    });
  }

  ngOnInit() { }
  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe()
    }
  }
}