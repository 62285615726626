import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormGroup, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';
import { AuthResponse } from '../../../domain/dtos/configuration/AuthResponse';
import { AppConfirmService } from '../../services/app-confirm/app-confirm.service';
import { ResetPasswordRequest } from '../../../domain/dtos/configuration/ResetPasswordRequest';
import { SessionsService } from '../../../auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../../views/users/users.service';
import { Observable, Subscription } from 'rxjs';
import * as fromAuth from '../../../auth/store';
import { PasswordMatchValidator } from '../../validators/passwordMatchValidator';
import { ResetModel } from '../../../auth/store/auth.effects';

@Component({
  selector: 'ebo-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  currentUser: AuthResponse;
  ownPasswordForm: UntypedFormGroup;
  formFromSignIn: UntypedFormGroup;
  generatedForm: UntypedFormGroup;
  resetOwnPassword: number;
  hasBeenOpened = false;
  botId$: Observable<number>;
  botIdSub: Subscription;
  botId: number;

  constructor(
    public snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private store: Store<fromRoot.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    public confirmService: AppConfirmService,
    private sessionService: SessionsService,
    public usersService: UsersService
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem('userObject'));
    this.botId$ = this.store.pipe(select(fromAuth.getBotId));

    this.botIdSub = this.botId$.subscribe((id: number) => {
      if (id) {
        this.botId = id;
      }
    });

    if (this.data) {
      this.formFromSignIn = this.fb.group(
        {
          email: ['', [Validators.required, Validators.email, Validators.pattern('/^[\\w-]+(\\.[\\w-]+)*@([\\w-]+\\.)+[a-zA-Z]{2,7}$/')]],
          password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
          confirmPassword: ['', [Validators.required]]
        },
        {
          validators: [PasswordMatchValidator('password', 'confirmPassword')]
        }
      );
    } else {
      this.ownPasswordForm = this.fb.group(
        {
          password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
          confirmPassword: ['', [Validators.required]]
        },
        {
          validators: [PasswordMatchValidator('password', 'confirmPassword')]
        }
      );

      this.generatedForm = this.fb.group({
        generatedPassword: ['']
      });
      this.generatedForm.get('generatedPassword').disable();
    }
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]/.test(value);
    const hasSpecialCharacter = /[!@#\$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value);
    const hasNumber = /\d/.test(value);

    if (!hasUpperCase || !hasSpecialCharacter || !hasNumber) {
      return { invalidPassword: true };
    }

    return null;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  changeFields(generated: number): void {
    this.resetOwnPassword = generated;

    this.ownPasswordForm.reset();

    if (!this.hasBeenOpened) {
      this.hasBeenOpened = true;
    }
  }

  generate(): void {
    this.confirmService
      .confirm({
        title: 'Reset Password',
        message: `Are you sure to reset the password of ${this.currentUser.username.toLowerCase()}?`
      })
      .subscribe((result: boolean) => {
        if (result) {
          this.usersService.resetPassword(this.botId, this.currentUser.userId).subscribe(
            (response: string) => {
              this.generatedForm.get('generatedPassword').setValue(response);
              this.snackBar.open(`Password generated. Please copy it from the field`, 'Dismiss');
            },
            (error: any): void => {
              this.snackBar.open(`The password could not be reset. ${error?.message}`, 'Dismiss', { duration: 3000 });
            }
          );
        }
      });
  }

  save(): void {
    if (this.ownPasswordForm.get('password').value) {
      this.confirmService
        .confirm({
          title: 'Change password',
          message: `Are you sure you want to change your password?`
        })
        .subscribe((result: boolean) => {
          if (result) {
            this.sessionService
              .generateUserCode(this.data ? this.ownPasswordForm.value.email : this.currentUser.email)
              .subscribe((code: string) => {
                let request: ResetPasswordRequest;
                request = {
                  email: this.data ? this.ownPasswordForm.value.email : this.currentUser.email,
                  password: this.ownPasswordForm.value.password,
                  confirmPassword: this.ownPasswordForm.value.confirmPassword,
                  code
                };

                this.sessionService.resetPassword(request).subscribe((response: ResetModel) => {
                  if (response.isSuccessfull) {
                    this.cancel();
                    this.snackBar.open(`Your password has been changed`, 'Dismiss');
                  }
                });
              });
          }
        });
    } else {
      this.snackBar.open(`The password must not be empty`, 'Dismiss', { duration: 3000 });
    }
  }
}
