import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// Material
import { MatDialog } from '@angular/material/dialog';

// Root Singleton Services
import { ApiService, HttpClientService } from 'app/core/services';

// ngRx
import { select, Store } from '@ngrx/store';
import * as fromRoot from 'app/core/store';
import { Observable, Subscription } from 'rxjs';
import * as fromAuth from '../../../../auth/store';
import { environment } from '../../../../../environments/environment.prod';

@Component({
  selector: 'ebo-webchat',
  templateUrl: './webchat.component.html',
  styleUrls: ['./webchat.component.scss']
})
export class WebchatComponent implements OnInit, OnDestroy {
  @Input() userChatId: string;
  @Input() chatUsername: string;
  env$: Observable<string>;
  botId$: Observable<number>;
  envSub: Subscription;
  env: string;
  botIdSub: Subscription;
  isScriptLoaded = false;

  constructor(
    public dialog: MatDialog,
    private store: Store<fromRoot.AppState>,
    private http: HttpClientService,
    private apiService: ApiService
  ) {
    this.env$ = this.store.pipe(select(fromAuth.getEnvironment));
    this.botId$ = this.store.pipe(select(fromAuth.getBotId));
    this.envSub = this.env$.subscribe(env => {
      if (env !== this.env) {
        this.env = env;
      }
    });
  }

  public loadScript(): void {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.src = `https://cdn.ebo.ai/webchat/ebo-chat-${environment.webchatFileName}.js`;
    script.id = 'webchat-widget';
    script.async = true;
    body.appendChild(script);
  }

  ngOnInit(): void {
    setTimeout(() => {
      const div = document.querySelector('#webchat-root');

      if (div) {
        div.setAttribute('data-env', this.env.toLowerCase());
        div.setAttribute(
          'data-name',
          this.apiService.conversationApiUrl
            .replace('https://', '')
            .replace('-conversation.ebo.ai', '')
            .replace('-conversation-core.ebo.ai', '')
            .replace('/', '')
        );
        div.setAttribute('data-userid', this.userChatId);
        div.setAttribute('data-chatusername', this.chatUsername);

        this.botIdSub = this.botId$.subscribe(id => {
          if (id) {
            div.setAttribute('data-botid', String(id));
          }
        });

        this.loadScript();
      }
    }, 300);
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('virtualAgentToken');
    sessionStorage.removeItem('virtualAgentSession');
    sessionStorage.removeItem('welcomeMessageExecuted');
    sessionStorage.removeItem('virtualAgentState');
    sessionStorage.removeItem('virtualAgentTime');

    const getScript = document.querySelector('#webchat-widget');

    if (getScript) {
      getScript.remove();
    }
    const tokenObj = JSON.parse(sessionStorage.getItem('virtualAgentToken'));

    if (this.envSub && this.botIdSub) {
      this.envSub.unsubscribe();
      this.botIdSub.unsubscribe();
      this.isScriptLoaded = false;
    }

    if (tokenObj) {
      const base64Url = tokenObj.token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      const obj = JSON.parse(jsonPayload);
      this.http.get(`${this.apiService.conversationApiUrl}conversations/deactivate?ConversationId=${obj.conv}`, true).subscribe();
    }
  }
}
