import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagService } from '../../views/settings/feature-flag/services/feature-flag.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {

  // create a constructor to inject the service
    constructor(
        private featureFlagService: FeatureFlagService,
        private router: Router,
    ) {
    }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.featureFlagService.getFeatureFlags().pipe(
            map((featureFlags) => {
                // loop through the feature flags and check if the feature flag is enabled
                for (const featureFlag of featureFlags) {
                    if (featureFlag.key === route.data.featureFlag) {
                        if (featureFlag.value === 'true') {
                            return true;
                        } else {
                            this.router.navigate(['/prod/reports/view']);
                        }
                    }
                }
                return true;
            }
        ));
  }

}
