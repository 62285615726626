import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'redirect',
  template: ''
})
export class RedirectComponent {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.router.navigateByUrl(window.location.pathname.replace(params['from'], params['to']) + window.location.search);
    });
  }
}
