

export class ForgotPasswordRequest {
    email: string;

    constructor() { 
   }
}

    
