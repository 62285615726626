import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, first, flatMap } from 'rxjs/operators';
import * as fromRoot from 'app/core/store';
import * as fromAuth from 'app/auth/store';
import { environment } from 'environments/environment';

@Injectable()
export class SessionsInterceptor implements HttpInterceptor {
    constructor(private store: Store<fromRoot.AppState>) {}
    

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
     // These do not need Sessions Token
     if(!req.url.startsWith("http") || // Resources for Material
        req.url == `${environment.apiUrl}/oauth/forgotpassword` ||  // Forgot Password
        req.url == `${environment.apiUrl}/oauth/resetpassword` ||  // Reset Password
        req.url == `${environment.apiUrl}/oauth/azure/token` ||  // Token for AzureAD
        req.url == `${environment.apiUrl}/oauth/twofa/generate` ||  // Token for AzureAD
        req.url == `${environment.apiUrl}/oauth/twofa` ||  // Token for AzureAD
        req.url == `${environment.apiUrl}/oauth/token`) {  // Token for UserPassword
        return next.handle(req);
     }

     return this.store.pipe(select(fromAuth.getToken))
      .pipe(
        filter(token => token != null),
        first(),
        flatMap(token => {
          const authReq = !!token ? req.clone({
            setHeaders: { Authorization: 'Bearer ' + token },
          }) : req;

          return next.handle(authReq);
        }),
      );
  }
}