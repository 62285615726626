import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';

//RxJs
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

// Root singleton Services
import { StorageHelperService } from './storage-helper.service';
import { ErrorService } from './error.service';
import { MsalService } from '@azure/msal-angular';

import { Store } from '@ngrx/store';
import * as fromRoot from 'app/core/store';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
// TODO add interceptor support of angular 4.1
export class HttpClientService {
  constructor(
    private http: HttpClient,
    private storageHelper: StorageHelperService,
    private errorService: ErrorService,
    private msalConfig: MsalService,
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private snackbar: MatSnackBar
    ) {}

  createAuthorizationHeader(headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }
      headers = headers.append('Content-Type', 'application/json')
    return headers;
  }

  createUploadHeader(headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('enctype', 'multipart/form-data')
    return headers;
  }

  request(httpRequest: HttpRequest<{}>): Observable<HttpEvent<{}>> {
    return this.http.request(httpRequest);
  }

  get<T>(url: string, showException: boolean,withAzureHeaders:boolean = false, isBlob:boolean = false): Observable<T> {
    var headers
    if (withAzureHeaders == false)
      headers = this.createAuthorizationHeader();
    else {
      headers = new HttpHeaders();
      headers = headers
        .set('Content-Type', 'application/json')
    }
    return this.http.get<T>(url, { headers: headers, responseType: isBlob ? 'blob' as 'json' : undefined })
    .pipe(catchError(error => {return this.handle(error, showException);
    }));
  }

  // getBLob(url: string, showException: boolean,withAzureHeaders:boolean = false): Observable<Blob>
  // {
  //   var headers
  //   if (withAzureHeaders == false)
  //     headers = this.createAuthorizationHeader();
  //   else {
  //     headers = new HttpHeaders();
  //     headers = headers
  //       .set('Content-Type', 'application/json')
  //   }
  // }

  // get<T>(url: string, showException: boolean, headers?:HttpHeaders): Observable<T> {
  //   if (headers == null)
  //     headers = this.createAuthorizationHeader();
  //   return this.http.get<T>(url, { headers: headers })
  //   .pipe(catchError(error => {return this.handle(error, showException);
  //   }));
  // }

  delete<T>(url: string, showException: boolean): Observable<T> {
    var headers = this.createAuthorizationHeader();
    return this.http.delete<T>(url, {
      headers: headers
    })
    .pipe(catchError(error => { return this.handle(error, showException);
    }));
  }

  handle(err: any, showException: boolean) {
    // const { status, error } = err;
    //
    // if (status === 401) {
    //   this.store.dispatch(authActions.logout());
    //   setTimeout(
    //     () => {
    //       this.snackbar.open(`${error}`, 'Close', {duration: 3000});
    //     }, 1100
    //   )
    // }

    if (showException) { return this.errorService.handle(err); }
    return throwError(this.errorService.format(err));
  }

  post<T>(url: string, data: any, showException: boolean): Observable<T> {
    var headers = this.createAuthorizationHeader();
    return this.http.post<T>(url, data, {
      headers: headers
    })
    .pipe(catchError(error => {return this.handle(error, showException);
    }));
  }

  postHeaders<T>(url: string, data: any, headers: HttpHeaders, showException: boolean): Observable<T> {
    headers = this.createAuthorizationHeader(headers);
    return this.http.post<T>(url, data, {
      headers: headers
    })
    .pipe(catchError(error => {return this.handle(error, showException);
    }));
  }

  put<T>(url: string, data: any, showException: boolean): Observable<T>  {
    var headers = this.createAuthorizationHeader();
    return this.http.put<T>(url, data, {
      headers: headers
    })
    .pipe(catchError(error => {return this.handle(error, showException);
    }));
  }

  patch<T>(url: string, data: any, showException: boolean): Observable<T> {
    var headers = this.createAuthorizationHeader();
    return this.http.patch<T>(url, data, {
      headers: headers
    })
    .pipe(catchError(error => {return this.handle(error, showException);
    }));
  }
}
