import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsResolver {
  public notificationsHub = new BehaviorSubject(null);

}
