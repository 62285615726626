<mat-card *ngIf="botInfoResponse" [ngClass]="contentHidden ? 'chat-card-bot-detail chat-card-close' : 'chat-card-bot-detail'">
  <mat-card-title>
    <div class="title">
      <span>Your virtual agent persona</span>
      <div>
        <mat-icon (click)="hideContent()">{{contentHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</mat-icon>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div class="content">
      <div class="bot-detail" *ngIf="botInfoResponse.botInfo || botInfoResponse.botInfo?.avatar || botInfoResponse.botInfo?.name || botInfoResponse.botInfo?.age || botInfoResponse.botInfo?.gender">
         <div class="bot-detail-avatar">
          <div class="avatar">
            <img *ngIf="botInfoResponse.botInfo.avatar" src="{{botInfoResponse.botInfo.avatar}}" />
            <img *ngIf="!botInfoResponse.botInfo.avatar" src="assets/images/bot-default.png" />
          </div>
        </div>
        <div class="bot-detail-info">
          <div class="bot-detail-info-row" *ngIf="botInfoResponse.botInfo.name">
            <div class="bot-detail-info-label">
              Name
            </div>
            <div class="bot-detail-info-content">
              {{botInfoResponse.botInfo.name}}
            </div>
          </div>
          <div class="bot-detail-info-row" *ngIf="botInfoResponse.botInfo.age">
            <div class="bot-detail-info-label">
              Age
            </div>
            <div class="bot-detail-info-content">
              {{botInfoResponse.botInfo.age}}
            </div>
          </div>
          <div class="bot-detail-info-row" *ngIf="botInfoResponse.botInfo.gender">
            <div class="bot-detail-info-label">
              Gender
            </div>
            <div class="bot-detail-info-content">
              {{botInfoResponse.botInfo.gender}}
            </div>
          </div>
        </div>
      </div>
      <div class="bot-characteristics" *ngIf="botInfoResponse.botInfo?.characteristics">
        <div class="bot-characteristics-header">
          Characteristics
        </div>
        <div class="bot-characteristics-content">
          {{botInfoResponse.botInfo?.characteristics}}
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
