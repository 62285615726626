import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';

// RxJs
import { filter, first } from 'rxjs/operators';

import {Store, select} from '@ngrx/store';
import * as fromRoot from 'app/core/store';
import * as fromAuth from 'app/auth/store';


@Component({
  selector: 'hangfire-redirect',
  template: ''
})
export class HangfireRedirectComponent implements OnInit {

  hangfireUrl : string;
  token : string;
  constructor(private apiService: ApiService, private store: Store<fromRoot.AppState>,) { 

      this.hangfireUrl = this.apiService.conversationApiUrl;
    }

  ngOnInit() {

    this.store.pipe(select(fromAuth.getToken))
    .pipe(
      filter(token => token != null),
      first()
      )
    .subscribe(token => {
        this.token = token;
    });

    window.location.href = this.hangfireUrl + "ebo-jobs?access_token=" + this.token;
  }

}
